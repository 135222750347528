const detectWebView = () => {
  const { userAgent } = navigator;
  const userAgentLowerCase = userAgent.toLowerCase();

  const isAndroidWebView = userAgentLowerCase.includes('wv')
    || userAgentLowerCase.includes('webview')
    || (userAgentLowerCase.includes('android')
      && userAgentLowerCase.includes('version'));

  const isIOSWebView = userAgentLowerCase.includes('iabmv');

  return { isAndroidWebView, isIOSWebView };
};

const isWebView = () => {
  const { isAndroidWebView, isIOSWebView } = detectWebView();

  return isAndroidWebView || isIOSWebView;
};

const useMobileDetection = () => ({ detectWebView, isWebView });

export default useMobileDetection;
